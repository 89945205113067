import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import windowSize from 'react-window-size';

import Aux from '../../../../../hoc/_Aux';
import NavGroup from './NavGroup';
import * as actionTypes from '../../../../../store/actions';
import RouterUtilities from '../../../../../helpers/RouterUtilities';
import { AuthUtils } from '../../../../../helpers/AuthUtils';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';
import { logout } from '../../../../actions';

class NavContent extends Component {
  state = {
    scrollWidth: 0,
    prevDisable: true,
    nextDisable: false,
  };

  constructor() {
    super();
    this.state = {
      inboxCount: 0,
      priceRequestCount: 0,
      unreadComments: 0,
    };
  }
  scrollPrevHandler = () => {
    const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;

    let scrollWidth = this.state.scrollWidth - wrapperWidth;
    if (scrollWidth < 0) {
      this.setState({ scrollWidth: 0, prevDisable: true, nextDisable: false });
    } else {
      this.setState({ scrollWidth: scrollWidth, prevDisable: false });
    }
  };

  scrollNextHandler = () => {
    const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;
    const contentWidth = document.getElementById('sidenav-horizontal').clientWidth;

    let scrollWidth = this.state.scrollWidth + (wrapperWidth - 80);
    if (scrollWidth > contentWidth - wrapperWidth) {
      scrollWidth = contentWidth - wrapperWidth + 80;
      this.setState({ scrollWidth: scrollWidth, prevDisable: false, nextDisable: true });
    } else {
      this.setState({ scrollWidth: scrollWidth, prevDisable: false });
    }
  };

  async componentDidMount() {}

  render() {
    let currentLink = '/';

    if (this.props && this.props.history?.location) {
      currentLink = RouterUtilities.getActiveRoute(this.props.history.location);
      if (currentLink.length === 0) {
        currentLink = 'dashboard';
      }
    }
    const navItems = this.props.navigation.map((item) => {
      item.children.forEach((navItem) => {
        if (navItem.id === 'inbox') {
          navItem.badge.title = this.state.inboxCount;
        }
        if (navItem.id === 'price-requests') {
          navItem.badge.title = this.state.priceRequestCount;
        }
      });

      switch (item.type) {
        case 'group':
          return (
            <NavGroup
              layout={this.props.layout}
              key={item.id}
              group={item}
              currentLink={currentLink}
            />
          );
        default:
          return false;
      }
    });

    let scrollStyle = {
      marginLeft: '-' + this.state.scrollWidth + 'px',
    };

    if (this.props.layout === 'horizontal' && this.props.rtlLayout) {
      scrollStyle = {
        marginRight: '-' + this.state.scrollWidth + 'px',
      };
    }

    let mainContent = '';

    if (this.props.layout === 'horizontal' || AuthUtils.isClientPortal()) {
      let prevClass = ['sidenav-horizontal-prev hidden'];
      if (this.state.prevDisable) {
        prevClass = [...prevClass, 'disabled'];
      }
      let nextClass = ['sidenav-horizontal-next hidden'];
      if (this.state.nextDisable) {
        nextClass = [...nextClass, 'disabled'];
      }
      const collapseUrl = '#';
      mainContent = (
        <div className="navbar-content sidenav-horizontal" id="layout-sidenav">
          <a href={collapseUrl} className={prevClass.join(' ')} onClick={this.scrollPrevHandler}>
            <span />
          </a>
          <div id="sidenav-wrapper" className="sidenav-horizontal-wrapper align-items-start">
            <ul
              id="sidenav-horizontal"
              className="nav pcoded-inner-navbar sidenav-inner"
              onMouseLeave={this.props.onNavContentLeave}
              style={scrollStyle}
            >
              {navItems}
            </ul>
          </div>
          <a href={collapseUrl} className={nextClass.join(' ')} onClick={this.scrollNextHandler}>
            <span />
          </a>
        </div>
      );
    } else {
      mainContent = (
        <div className="navbar-content next-scroll">
          <PerfectScrollbar>
            <ul className="nav pcoded-inner-navbar" id="nav-ps-next">
              {navItems}
              <li className="nav-item">
                <a href="/#" onClick={() => this.props.logUserOut()}>
                  <span className="pcoded-micon">
                    <i className="feather icon-log-out" />{' '}
                  </span>
                  <span className="pcoded-mtext">
                    {LocaleUtilities.i18n('menu-link-Log out', 'base', true)}
                  </span>
                </a>
              </li>
            </ul>
          </PerfectScrollbar>
        </div>
      );
    }

    return <Aux>{mainContent}</Aux>;
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    rtlLayout: state.rtlLayout,
    collapseMenu: state.collapseMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onNavContentLeave: () => dispatch({ type: actionTypes.NAV_CONTENT_LEAVE }),
    logUserOut: () => {
      dispatch({ type: actionTypes.COLLAPSE_MENU });
      dispatch(logout(null));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(windowSize(NavContent)));
