import { SortOrder } from './constant';
import LocaleUtilities from '../helpers/LocaleUtilities';

export class PaginatedSearchBaseOptionsResult {
  page: number = 1;
  sizePerPage: number = Pagination.sizePerPage;
  skip: number | undefined;
  take: number | undefined;
  sortName: string | undefined;
  sortOrder: SortOrder | undefined;

  public baseParse(obj: any) {
    if ('page' in obj) {
      this.page = Number(obj.page);
    }
    if ('sizePerPage' in obj) {
      this.sizePerPage = Number(obj.sizePerPage);
    }
    if ('skip' in obj) {
      this.skip = Number(obj.skip);
    }
    if ('take' in obj) {
      this.take = Number(obj.take);
    }
    if ('sortName' in obj) {
      this.sortName = obj.sortName;
    }
    if ('sortOrder' in obj) {
      this.sortOrder = obj.sortOrder;
    }
  }
}

export class PaginatedListResult<TModel> {
  contextObjects: Array<TModel> | undefined;
  totalCount: number | undefined;
  pages: number | undefined;
}

export class ValidationFailure {
  propertyName: string | undefined;
  attemptedValue: any;
  customState: any;
  errorCode: string | undefined;
  errorMessage: string;
}

export class ValidationResult {
  errors: Array<ValidationFailure> | undefined;
}

export interface ServiceResult<T> {
  payload: T;
  isSuccessful: boolean;
  message: string;
  validation: ValidationResult;
}

export enum ErrorType {
  GenericError = 0,
  ItemNotFound = 10,
  FailedResponseError = 20,
}

export class ErrorWrapper {
  title: any;
  description: string;
  formValidations: ValidationResult | undefined;
  type: ErrorType = ErrorType.GenericError;

  constructor(title: any, description?: string) {
    this.title = title;
    this.description = description || '';
  }
}

export class NotFoundErrorWrapper extends ErrorWrapper {
  constructor(description: string) {
    super('Error', description);
    this.type = ErrorType.ItemNotFound;
  }
}

export class FailedResponseErrorWrapper extends ErrorWrapper {
  constructor(description: string) {
    super('Error', description);
    this.type = ErrorType.FailedResponseError;
  }
}

export class TableData<T> {
  data: Array<T> | undefined;
  pagination: Pagination;

  public static createTableDataFrom<T>(
    paginatedListResult: PaginatedListResult<T>,
    page: number,
    sizePerPage: number,
  ): TableData<T> {
    const data = paginatedListResult.contextObjects;
    const pagination: Pagination = { total: paginatedListResult.totalCount, page, sizePerPage };
    return new TableData(data, pagination);
  }

  public static createTableDataFromArray<T>(
    paginatedListResult: Array<T>,
    page: number,
    sizePerPage: number,
    totalCount: number,
  ): TableData<T> {
    const data = paginatedListResult;
    const pagination: Pagination = { total: totalCount, page, sizePerPage };
    return new TableData(data, pagination);
  }

  public static getDefault<T>(): TableData<T> {
    return new TableData<T>([], Pagination.getDefault());
  }

  constructor(data: Array<T> | undefined, pagination: Pagination) {
    this.data = data;
    this.pagination = pagination;
  }
}

export class DataTableOptions {
  public static getDefault(
    count: number,
    page: number,
    rowsPerPage: number,
    onChangePage: (currentPage: number) => void,
    onChangeRowsPerPage: (numberOfRows: number) => void,
  ) {
    return {
      serverSide: true,
      filter: false,
      selectToolbarPlacement: 'none',
      filterType: 'multiselect',
      download: false,
      print: false,
      search: false,
      viewColumns: false,
      count: count,
      page: page - 1,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: Pagination.sizePerPageList,
      selectableRows: false,
      onChangePage,
      onChangeRowsPerPage,
      setTableProps: () => ({
        className: 'table table-striped table-hover dataTable border-collapse',
      }),
      textLabels: {
        body: {
          noMatch: LocaleUtilities.i18n(
            'base-table-Sorry, no matching records founds',
            'base',
            true,
          ),
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        pagination: {
          next: LocaleUtilities.i18n('base-table-Next Page', 'base', true),
          previous: LocaleUtilities.i18n('base-table-Previous Page', 'base', true),
          rowsPerPage: LocaleUtilities.i18n('base-table-Rows per page', 'base', true),
          displayRows: LocaleUtilities.i18n('base-table-of', 'base', true),
        },
      },
    };
  }
}

export class Pagination {
  total: number | undefined;
  page: number | undefined;
  sizePerPage: number | undefined;
  sortName?: string;

  public static sizePerPage = 25;

  public static sizePerPageList = [25, 50, 100];

  public static sizePerPageListLarger = [50, 100, 150];

  public static getDefault(): Pagination {
    return { total: 0, page: 1, sizePerPage: 25 };
  }
}
