import { SelectComponentValue } from '../viewModels/common/SelectComponentValue';

export enum Platforms {
  NotSelected = 0,
  TMS = 5,
  ClientPortal = 10,
  MobileApp = 15,
  API = 20,
}

export enum WeekDays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export enum InvoiceFrequency {
  NotSelected = 0,
  EveryMonth = 1,
  Quarterly = 2,
  HalfYearly = 3,
  Yearly = 4,
}

export enum PackageTypeGroup {
  NotSelected = 0,
  Pallet = 1,
  Package = 2,
  LoadingMeter = 3,
  FullTruck = 4,
  Container = 5,
}

export enum FleetType {
  NotSelected = 0,
  Vehicle = 1,
  Forklift = 2,
  CraneTruck = 3,
  TractorUnit = 4,
  Van = 5,
  TailLiftTruck = 9,
  Truck = 10,
  BoxSemiTrailer = 16,
  Tautliner = 17,
  FlatbedTrailer = 20,
  MegaTrailer = 21,
  RefrigeratedTrailer = 22,
  FullTrailer = 23,
  Container = 25,
  ContainerTruck = 30,
}

export enum CustomerPosition {
  NotSelected = 0,
  Logistics = 'Logistics',
  Warehouse = 'Warehouse',
  Sales = 'Sales',
  Accounting = 'Accounting',
  Other = 'Other',
}

export enum ShipmentStatus {
  NotSelected = 0,
  Draft = 1,
  Canceled = 5,
  Received = 10,
  Processing = 15,
  InTransit = 20,
  Delivered = 25,
}

export enum ShipmentStatusProblem {
  NotSelected = 0,
  Missing = 1,
  PartlyMissing = 2,
  NotCollected = 10,
  IncorrectPickupInformation = 11,
  PalletsAndGoodsHeightHigherThanPermitted = 12,
  CollectionBusinessClosed = 13,
  DamagedInTheCourseOfTransportation = 20,
  DamagedDuringManipulationInWarehouse = 21,
  WeatherConditions = 30,
  DestinationIncorrect = 40,
  SortedToWrongRoute = 41,
  SpecialUnloadingOperations = 42,
  DeliveryBusinessClosed = 43,
  DeliveryRefusedByRecipient = 44,
  DeliveryConsigneeAbsent = 45,
}

export enum PriceRequestStatus {
  NotSelected = 0,
  Draft = 1,
  Expired = 2,
  Canceled = 5,
  Received = 10,
  WaitingConfirmed = 20,
  Accepted = 25,
  Rejected = 30,
}

export enum PriceRequestRejectReason {
  NotSelected = 0,
  TooHighPrice = 1,
  Postpone = 2,
  DeliveryNotNeedAnyMore = 3,
  SomethingElse = 4,
}

export enum ShippingOrderStatus {
  NotSelected = 0,
  Draft = 1,
  Processing = 2,
  Canceled = 5,
  Collected = 10,
  InTransit = 20,
  Delivered = 25,
}

export enum ShipmentType {
  NotSelected = 0,
  Sender = 5,
  Receiver = 10,
}

export enum InvoicePriceType {
  NotSelected = 0,
  TransportDomestic = 1,
  TransportEU = 8,
  Transport = 9,
  HomeDelivery = 2,
  Customs = 3,
  Storage = 4,
  Insurance = 5,
  ForwardedTransportService = 6,
  LogisticsManagement = 7,
  Other = 100,
}

export enum InvoiceType {
  NotSelected = 0,
  Sales = 1,
  Purchase = 2,
}

export enum InvoiceStatus {
  NotSelected = 0,
  Draft = 5,
  Sent = 10,
  Paid = 15,
}

export enum UploadEntities {
  NotSelected = 0,
  TenantLogo = 1,
  Translations = 2,
  CustomerImport = 3,
  Shipment = 5,
  ShipmentPod = 6,
  ShippingOrder = 10,
}

export enum SavedFilterView {
  NotSelected = 0,
  Shipment = 2,
  ShipmentClientPortal = 3,
  ShippingOrder = 4,
  Inbox = 6,
  PriceRequest = 8,
  Project = 10,
}

export enum MessageTemplateType {
  NotSelected = 0,
  NoticeInvoice = 6,
  ShippingOrderForPartner = 7,
  SpotRequestEmail = 9,
}

export enum SpotRequestStatus {
  NotSelected = 0,
  Draft = 5,
  WaitingPrices = 10,
  PricesOffered = 11,
  Rejected = 15,
  Approved = 20,
  Expired = 25,
}

export enum SpotRequestEntity {
  NotSelected = 0,
  Shipment = 5,
  ShippingOrder = 10,
  PriceRequest = 15,
}

export enum TenantBusinessArea {
  NotSelected = 0,
  Forwarder = 1,
  Carrier = 2,
}

export const Incoterms: SelectComponentValue[] = [
  {
    value: 'DAP',
    label: 'DAP',
  },
  {
    value: 'EXW',
    label: 'EXW',
  },
  {
    value: 'FCA',
    label: 'FCA',
  },
  {
    value: 'CPT',
    label: 'CPT',
  },
  {
    value: 'CIP',
    label: 'CIP',
  },
  {
    value: 'DAT',
    label: 'DAT',
  },
  {
    value: 'DDP',
    label: 'DDP',
  },
  {
    value: 'FAS',
    label: 'FAS',
  },
  {
    value: 'FOB',
    label: 'FOB',
  },
  {
    value: 'CFR',
    label: 'CFR',
  },
  {
    value: 'CIF',
    label: 'CIF',
  },
];

export const TenantBusinessAreas: SelectComponentValue[] = [
  {
    value: TenantBusinessArea.Forwarder.toString(),
    label: 'Forwarder',
  },
  {
    value: TenantBusinessArea.Carrier.toString(),
    label: 'Carrier',
  },
];
export const TimeZones: SelectComponentValue[] = [
  {
    value: 'Morocco Standard Time',
    label: '(GMT) Casablanca',
  },
  {
    value: 'GMT Standard Time',
    label: '(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
  },
  {
    value: 'Greenwich Standard Time',
    label: '(GMT) Monrovia, Reykjavik',
  },
  {
    value: 'W. Europe Standard Time',
    label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    value: 'Central Europe Standard Time',
    label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    value: 'Romance Standard Time',
    label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'Central European Standard Time',
    label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    value: 'W. Central Africa Standard Time',
    label: '(GMT+01:00) West Central Africa',
  },
  {
    value: 'Jordan Standard Time',
    label: '(GMT+02:00) Amman',
  },
  {
    value: 'GTB Standard Time',
    label: '(GMT+02:00) Athens, Bucharest, Istanbul',
  },
  {
    value: 'Middle East Standard Time',
    label: '(GMT+02:00) Beirut',
  },
  {
    value: 'Egypt Standard Time',
    label: '(GMT+02:00) Cairo',
  },
  {
    value: 'South Africa Standard Time',
    label: '(GMT+02:00) Harare, Pretoria',
  },
  {
    value: 'FLE Standard Time',
    label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    value: 'Israel Standard Time',
    label: '(GMT+02:00) Jerusalem',
  },
  {
    value: 'E. Europe Standard Time',
    label: '(GMT+02:00) Minsk',
  },
  {
    value: 'Namibia Standard Time',
    label: '(GMT+02:00) Windhoek',
  },
  {
    value: 'Arabic Standard Time',
    label: '(GMT+03:00) Baghdad',
  },
  {
    value: 'Arab Standard Time',
    label: '(GMT+03:00) Kuwait, Riyadh',
  },
  {
    value: 'Russian Standard Time',
    label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
  },
  {
    value: 'E. Africa Standard Time',
    label: '(GMT+03:00) Nairobi',
  },
  {
    value: 'Georgian Standard Time',
    label: '(GMT+03:00) Tbilisi',
  },
  {
    value: 'Iran Standard Time',
    label: '(GMT+03:30) Tehran',
  },
  {
    value: 'Arabian Standard Time',
    label: '(GMT+04:00) Abu Dhabi, Muscat',
  },
  {
    value: 'Azerbaijan Standard Time',
    label: '(GMT+04:00) Baku',
  },
  {
    value: 'Mauritius Standard Time',
    label: '(GMT+04:00) Port Louis',
  },
  {
    value: 'Caucasus Standard Time',
    label: '(GMT+04:00) Yerevan',
  },
  {
    value: 'Afghanistan Standard Time',
    label: '(GMT+04:30) Kabul',
  },
  {
    value: 'Ekaterinburg Standard Time',
    label: '(GMT+05:00) Ekaterinburg',
  },
  {
    value: 'Pakistan Standard Time',
    label: '(GMT+05:00) Islamabad, Karachi',
  },
  {
    value: 'West Asia Standard Time',
    label: '(GMT+05:00) Tashkent',
  },
  {
    value: 'India Standard Time',
    label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    value: 'Sri Lanka Standard Time',
    label: '(GMT+05:30) Sri Jayawardenepura',
  },
  {
    value: 'Nepal Standard Time',
    label: '(GMT+05:45) Kathmandu',
  },
  {
    value: 'N. Central Asia Standard Time',
    label: '(GMT+06:00) Almaty, Novosibirsk',
  },
  {
    value: 'Central Asia Standard Time',
    label: '(GMT+06:00) Astana, Dhaka',
  },
  {
    value: 'Myanmar Standard Time',
    label: '(GMT+06:30) Yangon (Rangoon)',
  },
  {
    value: 'SE Asia Standard Time',
    label: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'North Asia Standard Time',
    label: '(GMT+07:00) Krasnoyarsk',
  },
  {
    value: 'China Standard Time',
    label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    value: 'North Asia East Standard Time',
    label: '(GMT+08:00) Irkutsk, Ulaan Bataar',
  },
  {
    value: 'Singapore Standard Time',
    label: '(GMT+08:00) Kuala Lumpur, Singapore',
  },
  {
    value: 'W. Australia Standard Time',
    label: '(GMT+08:00) Perth',
  },
  {
    value: 'Taipei Standard Time',
    label: '(GMT+08:00) Taipei',
  },
  {
    value: 'Tokyo Standard Time',
    label: '(GMT+09:00) Osaka, Sapporo, Tokyo',
  },
  {
    value: 'Korea Standard Time',
    label: '(GMT+09:00) Seoul',
  },
  {
    value: 'Yakutsk Standard Time',
    label: '(GMT+09:00) Yakutsk',
  },
  {
    value: 'Cen. Australia Standard Time',
    label: '(GMT+09:30) Adelaide',
  },
  {
    value: 'AUS Central Standard Time',
    label: '(GMT+09:30) Darwin',
  },
  {
    value: 'E. Australia Standard Time',
    label: '(GMT+10:00) Brisbane',
  },
  {
    value: 'AUS Eastern Standard Time',
    label: '(GMT+10:00) Canberra, Melbourne, Sydney',
  },
  {
    value: 'West Pacific Standard Time',
    label: '(GMT+10:00) Guam, Port Moresby',
  },
  {
    value: 'Tasmania Standard Time',
    label: '(GMT+10:00) Hobart',
  },
  {
    value: 'Vladivostok Standard Time',
    label: '(GMT+10:00) Vladivostok',
  },
  {
    value: 'Central Pacific Standard Time',
    label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
  },
  {
    value: 'New Zealand Standard Time',
    label: '(GMT+12:00) Auckland, Wellington',
  },
  {
    value: 'Fiji Standard Time',
    label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
  },
  {
    value: 'Tonga Standard Time',
    label: "(GMT+13:00) Nuku'alofa",
  },
  {
    value: 'Azores Standard Time',
    label: '(GMT-01:00) Azores',
  },
  {
    value: 'Cape Verde Standard Time',
    label: '(GMT-01:00) Cape Verde Is.',
  },
  {
    value: 'Mid-Atlantic Standard Time',
    label: '(GMT-02:00) Mid-Atlantic',
  },
  {
    value: 'E. South America Standard Time',
    label: '(GMT-03:00) Brasilia',
  },
  {
    value: 'Argentina Standard Time',
    label: '(GMT-03:00) Buenos Aires',
  },
  {
    value: 'SA Eastern Standard Time',
    label: '(GMT-03:00) Georgetown',
  },
  {
    value: 'Greenland Standard Time',
    label: '(GMT-03:00) Greenland',
  },
  {
    value: 'Montevideo Standard Time',
    label: '(GMT-03:00) Montevideo',
  },
  {
    value: 'Newfoundland Standard Time',
    label: '(GMT-03:30) Newfoundland',
  },
  {
    value: 'Atlantic Standard Time',
    label: '(GMT-04:00) Atlantic Time (Canada)',
  },
  {
    value: 'SA Western Standard Time',
    label: '(GMT-04:00) La Paz',
  },
  {
    value: 'Central Brazilian Standard Time',
    label: '(GMT-04:00) Manaus',
  },
  {
    value: 'Pacific SA Standard Time',
    label: '(GMT-04:00) Santiago',
  },
  {
    value: 'Venezuela Standard Time',
    label: '(GMT-04:30) Caracas',
  },
  {
    value: 'SA Pacific Standard Time',
    label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
  },
  {
    value: 'Eastern Standard Time',
    label: '(GMT-05:00) Eastern Time (US & Canada)',
  },
  {
    value: 'US Eastern Standard Time',
    label: '(GMT-05:00) Indiana (East)',
  },
  {
    value: 'Central America Standard Time',
    label: '(GMT-06:00) Central America',
  },
  {
    value: 'Central Standard Time',
    label: '(GMT-06:00) Central Time (US & Canada)',
  },
  {
    value: 'Central Standard Time (Mexico)',
    label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
  },
  {
    value: 'Canada Central Standard Time',
    label: '(GMT-06:00) Saskatchewan',
  },
  {
    value: 'US Mountain Standard Time',
    label: '(GMT-07:00) Arizona',
  },
  {
    value: 'Mountain Standard Time (Mexico)',
    label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
  },
  {
    value: 'Mountain Standard Time',
    label: '(GMT-07:00) Mountain Time (US & Canada)',
  },
  {
    value: 'Pacific Standard Time',
    label: '(GMT-08:00) Pacific Time (US & Canada)',
  },
  {
    value: 'Pacific Standard Time (Mexico)',
    label: '(GMT-08:00) Tijuana, Baja California',
  },
  {
    value: 'Alaskan Standard Time',
    label: '(GMT-09:00) Alaska',
  },
  {
    value: 'Hawaiian Standard Time',
    label: '(GMT-10:00) Hawaii',
  },
  {
    value: 'Samoa Standard Time',
    label: '(GMT-11:00) Midway Island, Samoa',
  },
  {
    value: 'Dateline Standard Time',
    label: '(GMT-12:00) International Date Line West',
  },
];
