import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as Actions } from './actions/ChangePassword.submit.action';
import { ErrorWrapper } from 'store/base';

export const REDUCER_KEY__CHANGE_PASSWORD = 'change_password';
interface State {
  errorWrapper?: ErrorWrapper;
}
const defaultState: State = {};
export default (state: State = defaultState, action: Action) => {
  if (isType(action, Actions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, Actions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, Actions.failed)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      errorWrapper: action.payload.error,
    };
  }

  return state;
};
