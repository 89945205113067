import * as React from 'react';
import moment, { Moment } from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import LocaleUtilities from './LocaleUtilities';
import Badge from 'reactstrap/lib/Badge';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import TextUtilities from './TextUtilities';

export default class TableFormattingUtilities {
  public static dateTimeFormat(cell?: Date | Moment): JSX.Element {
    if (!cell) {
      return <span />;
    }
    return <div>{moment(cell).format(LocaleUtilities.DateTimeFormat)}</div>;
  }

  public static dateFormatString(cell?: Date | Moment | string): string {
    if (!cell) {
      return '';
    }
    return moment(cell).format(LocaleUtilities.DateFormat);
  }

  public static dateFormat(cell: any): JSX.Element {
    if (!cell) {
      return <span />;
    }
    return <span>{moment(cell).format(LocaleUtilities.DateFormat)}</span>;
  }

  public static cellWithTooltip(cell: any): JSX.Element {
    if (!cell) {
      return <span />;
    }
    return (
      <Tooltip title={cell !== null ? cell : ''}>
        <span>{TextUtilities.truncate(cell, 12, '...')}</span>
      </Tooltip>
    );
  }

  public static currencyFormat(cell: number, currency?: string): JSX.Element {
    if (!cell && cell !== 0) {
      return <div />;
    }
    if (!currency) currency = '€';
    return <div>{`${cell.toFixed(2)} ${currency}`}</div>;
  }

  public static currencyFormatWithoutCurrency(cell: number): JSX.Element {
    if (!cell) {
      return <div />;
    }
    return <div>{`${cell.toFixed(2)}`}</div>;
  }

  public static boldTextFormat(cell: any): JSX.Element {
    if (!cell) {
      return <div />;
    }
    return <span className="font-bold">{`${cell}`}</span>;
  }

  public static booleanStatusFormat(value: boolean): JSX.Element {
    if (value === null || value === undefined) {
      return <div />;
    }
    if (value !== undefined && value === true) {
      return (
        <Badge color={'success'}>
          {LocaleUtilities.i18n(`base-status-Yes`, 'base.status', true)}
        </Badge>
      );
    } else {
      return (
        <Badge color={'warning'}>
          {LocaleUtilities.i18n(`base-status-No`, 'base.status', true)}
        </Badge>
      );
    }
  }
  public static boldTextFormatWithLink = (cell: any, row: any, path?: string): JSX.Element => {
    if (path === undefined) {
      return TableFormattingUtilities.boldTextFormat(cell);
    }
    return (
      <Link to={path} className={''}>
        {cell}
      </Link>
    );
  };

  public static actionFormat = (
    cell: any,
    row: any,
    path: string,
    deleteAction: (id: number) => any,
  ): JSX.Element => {
    return <div className="text-right"></div>;
  };
}

interface TableActionButtonsComponentProps {
  editLinkPath: string;
  deleteAction: () => any;
}

class TableActionButtonsComponentInner extends React.Component<
  TableActionButtonsComponentProps & RouteComponentProps<any>
> {
  render() {
    return <div></div>;
  }
}

export const TableActionButtonsComponent = withRouter(TableActionButtonsComponentInner);
