import * as React from 'react';
import moment, { Moment } from 'moment';
import {
  LOCAL_STORAGE_CURRENT_LANGUAGE,
  LOCAL_STORAGE_CURRENT_LANGUAGE_DEBUG,
} from '../store/constant';
import { UncontrolledTooltip } from 'reactstrap';
import TranslationApi from '../api/Translations.api';
import countries, { CountryOption } from '../seed/CountryOptions';
import { SelectComponentValue } from '../viewModels/common/SelectComponentValue';

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

export default class LocaleUtilities {
  public static DateFormat: string = 'DD.MM.YYYY';
  public static DateTimeFormat: string = 'DD.MM.YYYY HH:mm';
  public static i: number = 1;

  public static setMomentLocale() {
    if (LocaleUtilities.getCurrentLanguage() === 'Estonian') {
      moment.locale('et');
    } else if (LocaleUtilities.getCurrentLanguage() === 'English') {
      moment.locale('en-gb');
    } else {
      moment.locale('fi');
    }
  }

  public static translateSelectCompnentLabels(
    values: Array<SelectComponentValue>,
  ): Array<SelectComponentValue> {
    values.forEach(function (elem) {
      elem.label = I18n.t(elem.label);
    });
    return values;
  }

  public static trySetDate(param: any, useDefaultFormat: boolean = true): Moment | undefined {
    if (!param) {
      return undefined;
    }

    if (useDefaultFormat) {
      return moment(param, LocaleUtilities.DateFormat);
    } else {
      return moment(param);
    }
  }

  public static getCountryLabel(value?: string) {
    let country = '';
    countries.forEach(function (element: CountryOption) {
      if (value === element.value) {
        country = element.label;
      }
    });
    return country;
  }

  public static getFlagByLanguage(language: ValidLanguage): ValidFlag {
    if (language === 'English') {
      return 'flag-icon-us';
    }
    if (language === 'Finnish') {
      return 'flag-icon-fi';
    }
    return 'flag-icon-us';
  }

  public static setCurrentLanguage(language: ValidLanguage): void {
    localStorage[LOCAL_STORAGE_CURRENT_LANGUAGE] = language;
  }

  public static getCurrentLanguage(): ValidLanguage {
    let currentLang = localStorage[LOCAL_STORAGE_CURRENT_LANGUAGE];
    if (currentLang === '[object Object]') {
      currentLang = null;
    }
    return currentLang || 'Estonian';
  }

  public static getCurrentAppLanguage(languages: any[]) {
    let currentLang = LocaleUtilities.getCurrentLanguage();
    let res = languages[0];
    languages.forEach(function (element: any) {
      if (currentLang === element.name) {
        res = element;
      }
    });

    return res;
  }

  public static i18n(key: string, group?: string, onlyText: boolean = false): any {
    const debug = LocaleUtilities.getDebug();

    /* VAJALIK NÄITEKS PLACEHOLDERI-i jaoks */
    if (onlyText && !debug) {
      return I18n.t(key);
    }
    key = key.replace('.', '');

    if (!debug) {
      return <Translate value={key} />;
    }

    if (key && key.indexOf('.') > -1) {
      console.error(`${key} cannot contain . symbol. Replace it, for example, with -.`);
    }

    LocaleUtilities.i += 1;
    const targetKey = 'translation_' + LocaleUtilities.i;

    var value = I18n.t(key);

    const currentLang = LocaleUtilities.getCurrentLanguage() + ':';
    if (value && value.substring(0, 2) === '[[') {
      var rest = value.slice(2, -2);
      TranslationApi.upsert(key, { english: rest, finnish: rest, estonian: rest }, group);
      onlyText = true;
    }

    /* VAJALIK NÄITEKS PLACEHOLDERI-i jaoks */
    if (onlyText) {
      return value;
    }
    return (
      <span
        className="debug"
        id={targetKey}
        onClick={(e) => {
          // Brainstorm
          const newValue = prompt(currentLang, value);
          let english: any | null;
          let finnish: any | null;
          let estonian: any | null;
          if (LocaleUtilities.getCurrentLanguage() === 'English') {
            english = newValue;
          } else if (LocaleUtilities.getCurrentLanguage() === 'Finnish') {
            finnish = newValue;
          } else {
            estonian = newValue;
          }
          if (english !== undefined || finnish !== undefined || estonian !== undefined) {
            TranslationApi.upsert(key, { english, finnish, estonian }, group).then(() => {
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            });
          }
        }}
      >
        <Translate value={key} />
        <UncontrolledTooltip placement="right" target={targetKey}>
          {key}
        </UncontrolledTooltip>
      </span>
    );
  }

  public static toggleDebug(): void {
    const currentDebug = LocaleUtilities.getDebug();
    localStorage[LOCAL_STORAGE_CURRENT_LANGUAGE_DEBUG] = !currentDebug;
  }

  public static getDebug(): boolean {
    return localStorage[LOCAL_STORAGE_CURRENT_LANGUAGE_DEBUG] === 'true' || false;
  }
}

export type ValidLanguage = 'English' | 'Finnish' | 'Estonian';
export type ValidFlag = 'flag-icon-us' | 'flag-icon-fi';
