import { routePaths } from './store/api.constants';

export default {
  items: [
    {
      id: 'menu',
      title: '',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'orders',
          title: 'sidebar-menu-Orders',
          type: 'item',
          url: routePaths.dashboard1,
          classes: 'nav-item',
          icon: 'fa fa-boxes',
        },
        {
          id: 'car_switch',
          title: 'sidebar-menu-Switch car',
          type: 'item',
          url: routePaths.switchVehicle,
          classes: 'nav-item',
          icon: 'feather icon-shuffle',
        },
      ],
    },
  ],
};
