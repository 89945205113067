export default class TextUtilities {
  public static truncate = function (str, length, ending): string {
    if (!str) {
      return '';
    }
    if (length === null) {
      length = 100;
    }
    if (ending === null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
}
