import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { start } from './App/actions';
import App from './App/index';
import * as serviceWorker from './serviceWorker';
import createStore from './store';
import config from './config';
import './assets/scss/style.scss';
import { CookiesProvider } from 'react-cookie';

export const store: any = createStore(window['__INITIAL_STATE__']);
store.dispatch(start());
setTimeout(() => {
  const app = (
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter basename={config.basename}>
          <Route path="/" component={App} />
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  );

  ReactDOM.render(app, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
}, 450);
