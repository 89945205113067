import {
  InvoiceStatus,
  PriceRequestStatus,
  ShipmentStatus,
  ShipmentStatusProblem,
  ShippingOrderStatus,
} from './enums';
import { ValidLanguage } from '../helpers/LocaleUtilities';

const SYSTEM = {
  SITE_NAME: 'Cargo Keep',
  BLANK_LINK: '/',
};

export enum AppUserRoles {
  Admin = 'Administrator',
  TenantAdmin = 'TenantAdministrator',
  TenantUser = 'TenantUser',
  TenantCustomer = 'TenantCustomer',
}

export enum FilterDatePeriod {
  NotSelected = 0,
  Today = 1,
  Yesterday = 2,
  ThisWeek = 3,
  LastWeek = 4,
  ThisMonth = 5,
  LastMonth = 6,
  ThisYear = 7,
  LastYear = 8,
  Tomorrow = 9,
}

export default SYSTEM;

export const IS_DEV = process.env.NODE_ENV === 'development';

export const APP_NAME = 'tms';
export const APP_PORTAL_NAME = 'portal';
export const MOBILE_APP_NAME = 'app';

export const IS_PRODUCTION =
  `https://${APP_NAME}.cargokeep.com` === window.location.origin ||
  `https://${APP_PORTAL_NAME}.cargokeep.com` === window.location.origin;

export const IS_MOBILE_APP_URL =
  `https://${MOBILE_APP_NAME}.cargokeep.com` === window.location.origin;

export const LOCAL_STORAGE_TOKEN_KEY = 'cargokeep_id_token';
export const LOCAL_STORAGE_PROFILE_KEY = 'cargokeep_profile';
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'cargokeep_id_refresh_token';
export const LOCAL_STORAGE_THEME_SETTINGS = 'cargokeep_theme_settings';

export const LOCAL_STORAGE_YOU_WERE_LOGGED_OFF_MESSAGE = 'cargokeep_you_were_logged_off';
export const LOCAL_STORAGE_CURRENT_LANGUAGE = 'cargokeep_current_language';
export const LOCAL_STORAGE_CURRENT_LANGUAGE_DEBUG = 'cargokeep_current_language_debug';

export const LOCAL_STOARGE_URL_CONFIG = 'urlConfig';

export const LOCAL_STORAGE_APP_SCOPE = 'cargokeep_app_scope';

export type SortOrder = 'asc' | 'desc';

export enum AppScope {
  superadmin = 'superadmin',
  tenant = 'tenant',
  clientportal = 'clientportal',
}

export type Language = {
  languageId: string;
  locale: 'en' | 'ee';
  name: ValidLanguage;
  icon: 'gb' | 'ee';
  label: 'English' | 'Eesti keel';
};
export const languages: Language[] = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'gb',
    label: 'English',
  },
  {
    languageId: 'estonian',
    locale: 'ee',
    name: 'Estonian',
    icon: 'ee',
    label: 'Eesti keel',
  },
];

export const tenantCodeMinLength = 2;

export const notDeliveredShipmentStatuses = [
  ShipmentStatus[ShipmentStatus.Received],
  ShipmentStatus[ShipmentStatus.Processing],
  ShipmentStatus[ShipmentStatus.InTransit],
];

export const shipmentFilterStatuses = [
  ShipmentStatus[ShipmentStatus.Received],
  ShipmentStatus[ShipmentStatus.Processing],
  ShipmentStatus[ShipmentStatus.InTransit],
  ShipmentStatus[ShipmentStatus.Delivered],
  ShipmentStatus[ShipmentStatus.Canceled],
];

export const priceRequestFilterStatuses = [
  PriceRequestStatus[PriceRequestStatus.Received],
  PriceRequestStatus[PriceRequestStatus.WaitingConfirmed],
  PriceRequestStatus[PriceRequestStatus.Accepted],
  PriceRequestStatus[PriceRequestStatus.Rejected],
  PriceRequestStatus[PriceRequestStatus.Canceled],
];

export const shippingOrderFilterStatuses = [
  ShippingOrderStatus[ShippingOrderStatus.Draft],
  ShippingOrderStatus[ShippingOrderStatus.Canceled],
  ShippingOrderStatus[ShippingOrderStatus.Processing],
  ShippingOrderStatus[ShippingOrderStatus.InTransit],
  ShippingOrderStatus[ShippingOrderStatus.Delivered],
];

export const invoiceFilterStatuses = [
  InvoiceStatus[InvoiceStatus.Draft],
  InvoiceStatus[InvoiceStatus.Sent],
  InvoiceStatus[InvoiceStatus.Paid],
];

export const shipmentPickupProblems = [
  ShipmentStatusProblem.NotCollected,
  ShipmentStatusProblem.IncorrectPickupInformation,
  ShipmentStatusProblem.CollectionBusinessClosed,
  ShipmentStatusProblem.PalletsAndGoodsHeightHigherThanPermitted,
  ShipmentStatusProblem.DamagedDuringManipulationInWarehouse,
  ShipmentStatusProblem.PartlyMissing,
  ShipmentStatusProblem.WeatherConditions,
];

export const shipmentDeliveryProblems = [
  ShipmentStatusProblem.DestinationIncorrect,
  ShipmentStatusProblem.DeliveryBusinessClosed,
  ShipmentStatusProblem.SortedToWrongRoute,
  ShipmentStatusProblem.DeliveryConsigneeAbsent,
  ShipmentStatusProblem.DeliveryRefusedByRecipient,
  ShipmentStatusProblem.DamagedDuringManipulationInWarehouse,
  ShipmentStatusProblem.DamagedInTheCourseOfTransportation,
  ShipmentStatusProblem.PartlyMissing,
  ShipmentStatusProblem.WeatherConditions,
];
