import React, { ChangeEvent, FC, ReactElement, useCallback, useState } from 'react';
import Aux from 'hoc/_Aux';
import debounce from 'lodash.debounce';
import { PlatformSearchIndexResult } from 'shared/types/PlatformSearchIndexResult';
import fetchPlatformSearch from 'shared/actions/PlatformSearch.fetch.action';
import { Collapse } from 'reactstrap';
import LoadingComponent from 'components/Form/LoadingComponent';
import { NavLink } from 'react-router-dom';
import LocaleUtilities from 'helpers/LocaleUtilities';

const NavSearch: FC = (): ReactElement => {
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<PlatformSearchIndexResult[]>([]);
  const [showSearchResult, setShowSearchResult] = useState(false);

  const loadResult = async (searchText?: string) => {
    setIsLoading(true);
    setShowSearchResult(true);
    if (!searchText) {
      setSearchResult([]);
      setShowSearchResult(false);
    } else {
      const search = await fetchPlatformSearch({
        keyword: searchText,
        includeIds: [],
        getAll: true,
      });
      if (search) setSearchResult(search);
    }
    setIsLoading(false);
  };

  const closeSearch = () => {
    setIsSearch(false);
    setSearchResult([]);
    setShowSearchResult(false);
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => loadResult(nextValue), 750),
    [], // will be created only once initially
  );

  const onChangeInput = (input: ChangeEvent<HTMLInputElement>) => {
    // Even though handleChange is created on each render and executed
    // it references the same debouncedSave that was created initially
    debouncedSave(input.target.value);
  };

  const ResultComponent: FC<{ item: PlatformSearchIndexResult }> = ({ item }): ReactElement => {
    let path = '';
    const objectTranslation = LocaleUtilities.i18n(
      `platform-search-${item.object}`,
      'platform-search',
      true,
    );
    return (
      <div className="media" key={item.gd}>
        <div className="media-left mr-3">
          <span className="btn btn-icon btn-sm">
            <i className="fa fa-hand-o-right font-18" />
          </span>
        </div>
        <div className="media-body">
          <div className="chat-header f-w-400 mb-1">
            <NavLink className="btn-link text-primary font-16" to={path}>
              {item.label}
            </NavLink>
          </div>
          <p className="chat-header  text-muted font-12">{objectTranslation}</p>
        </div>
      </div>
    );
  };

  return (
    <Aux>
      <span className="pop-search pointer" onClick={() => setIsSearch(!isSearch)}>
        <i className="feather icon-search" />
      </span>
      <div className="search-bar" style={{ display: isSearch ? 'block' : 'none' }}>
        <input
          type="text"
          className="form-control border-0 shadow-none"
          placeholder={LocaleUtilities.i18n(
            'platform-search-Search result',
            'platform-search',
            true,
          )}
          onChange={(e) => onChangeInput(e)}
        />
        <button type="button" className="close" aria-label="Close" onClick={closeSearch}>
          <span aria-hidden="true">&times;</span>
        </button>
        <Collapse isOpen={showSearchResult} className="platform-search">
          <LoadingComponent isLoading={isLoading} showOverlayType={'mt-4'} />
          <h5>{LocaleUtilities.i18n('platform-search-Search result', 'platform-search', true)}:</h5>
          {searchResult.map((item, key) => {
            return <ResultComponent item={item} key={key} />;
          })}
        </Collapse>
      </div>
    </Aux>
  );
};
export default NavSearch;
