import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import AuthUtilities from 'helpers/AuthUtils/AuthUtils';
import { ErrorWrapper } from 'store/base';
import CommonUtilities from 'helpers/CommonUtilities';
import { routePaths } from 'store/api.constants';

const actionCreator = actionCreatorFactory();
const type = 'APP/LOGOUT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submitAsync(onSuccess: Function | null) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(asyncActions.started({}));

    async function mainAction(): Promise<void> {
      new AuthUtilities().unsetToken();
      dispatch(asyncActions.done({ params: {}, result: {} }));
      if (onSuccess) {
        onSuccess();
      } else {
        window.location.href = routePaths.login;
      }
    }

    async function catchAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
