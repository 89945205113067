import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { NullableLocalStorageUserModel } from 'helpers/AuthUtils';
import { ErrorWrapper } from 'store/base';
import { asyncActions as startActions } from './actions/App.start.action';
import { asyncActions as loginActions } from './routes/Sessions/Login/actions/App.login.submit.action';

export const REDUCER_NAME__APP = 'mobile_app';

export interface AppReduxState {
  loading: boolean;
  user: NullableLocalStorageUserModel;
  settings: any;
  error?: ErrorWrapper;
  loginSuccessful: boolean;
  lastUsername?: string;
  lastClientCode?: string;
}

const defaultState: AppReduxState = {
  loading: false,
  user: null,
  settings: {},
  loginSuccessful: false,
  lastUsername: undefined,
  lastClientCode: undefined,
};

export default (state: AppReduxState = defaultState, action: Action) => {
  // Set or unset user
  if (isType(action, startActions.started)) {
    return { ...state, type: action.type };
  }
  if (isType(action, startActions.done)) {
    return { ...state, type: action.type, loading: false, user: action.payload.result.user };
  }
  if (isType(action, startActions.failed)) {
    return { ...state, type: action.type, loading: false, user: null, error: action.payload.error };
  }

  // Login actions
  if (isType(action, loginActions.started)) {
    return {
      ...state,
      type: action.type,
      loading: true,
      user: state.user,
      error: undefined,
      loginSuccessful: false,
      lastUsername: action.payload.lastUsername,
      lastClientCode: undefined,
    };
  }
  if (isType(action, loginActions.done)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      user: action.payload.result.user,
      error: undefined,
      loginSuccessful: true,
      lastUsername: undefined,
      lastClientCode: undefined,
    };
  }
  if (isType(action, loginActions.failed)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      user: state.user,
      error: action.payload.error,
    };
  }
  return state;
};
