import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import Aux from 'hoc/_Aux';
import ScrollToTop from './layout/ScrollToTop';
import routes from '../route';
import { routePaths } from 'store/api.constants';
import asyncComponent from 'util/asyncComponent';
import MainAppComponent from './MainApp.component';
import { injectAsyncReducer } from '../store';
import { store } from '../index';
import { REDUCER_KEY__CHANGE_PASSWORD } from './routes/Sessions/ChangePassword/ChangePassword.reducer';
import { RouteComponentProps, withRouter } from 'react-router';

export interface AppComponentStateProps {
  loading: boolean;
  isLoggedIn: boolean;
  settings: any;
}

export interface AppComponentDispatchProps {}

const RestrictedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      rest.isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routePaths.login,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends React.Component<
  AppComponentStateProps & AppComponentDispatchProps & RouteComponentProps<any>
> {
  async componentDidMount() {
    // @ts-ignore
    const location = window.location;
    if (!this.props.isLoggedIn && location.pathname.length <= 1) {
      this.props.history.push(routePaths.login);
    }
  }

  render() {
    const { isLoggedIn } = this.props;
    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });
    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {menu}
              {isLoggedIn ? (
                <RestrictedRoute path={`/`} isLoggedIn={isLoggedIn} component={MainAppComponent} />
              ) : (
                <>
                  <Route
                    path={routePaths.changePassword}
                    component={asyncComponent(
                      async () => await import('./routes/Sessions/ResetPassword'),
                    )}
                  />
                  <Route
                    path={routePaths.resetPassword}
                    exact={true}
                    component={asyncComponent(async () => {
                      const reducer = await import(
                        './routes/Sessions/ChangePassword/ChangePassword.reducer'
                      );
                      injectAsyncReducer(store, REDUCER_KEY__CHANGE_PASSWORD, reducer.default);
                      return await import('./routes/Sessions/ChangePassword');
                    })}
                  />
                  <Route
                    path={routePaths.login}
                    component={asyncComponent(async () => await import('./routes/Sessions/Login'))}
                  />
                  <Route
                    path={routePaths.errors.offline}
                    component={asyncComponent(
                      async () => await import('./routes/Errors/OfflineRoute'),
                    )}
                  />
                </>
              )}
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    );
  }
}

export default withRouter(App);
