import React, { Component } from 'react';
import { connect } from 'react-redux';
import windowSize from 'react-window-size';

import NavSearch from './NavSearch';
import Aux from 'hoc/_Aux';
import * as actionTypes from 'store/actions';
import { AuthUtils } from 'helpers/AuthUtils';
import HouseRoundedIcon from 'components/Icons/House/HouseRoundedIcon';
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import LocaleUtilities from 'helpers/LocaleUtilities';

class NavLeft extends Component {
  quickLinkComponent(key: number, url: string, title: string): JSX.Element {
    return (
      <>
        <Dropdown.Item eventKey={key.toString()}>
          <NavLink to={url}>
            <i className="fa fa-arrow-right" /> {title}
          </NavLink>
        </Dropdown.Item>
      </>
    );
  }
  render() {
    const userData = AuthUtils.currentUserData();
    return (
      <Aux>
        <ul className="navbar-nav mr-auto">
          {!AuthUtils.isClientPortal() && (
            <li className="nav-item">
              <Dropdown alignRight={true} drop={'right'} className={'dropdown'}>
                <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                  <Tooltip title={'Shortcut links'}>
                    <i className="fa fa-paper-plane" />
                  </Tooltip>
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight className="quick-links">
                  <Dropdown.Header>
                    {LocaleUtilities.i18n('base-quicklinks-Quick links', 'base', true)}
                  </Dropdown.Header>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          )}
          {!AuthUtils.isClientPortal() && (
            <li className="nav-item pl-2">
              <NavSearch />
            </li>
          )}
          <li>
            <span className="text-white font-16 pl-4">
              <HouseRoundedIcon fill={'#ffffff'} /> {userData?.company}{' '}
              {AuthUtils.isClientPortal() && (
                <> - {LocaleUtilities.i18n('base-Client portal', 'base', true)}</>
              )}
            </span>
          </li>
        </ul>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFullScreen: state.isFullScreen,
    rtlLayout: state.rtlLayout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFullScreen: () => dispatch({ type: actionTypes.FULL_SCREEN }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(NavLeft));
