import moment, { Moment } from 'moment';
import addMinutes from 'date-fns/addMinutes';
import parseISO from 'date-fns/parseISO';
import TableFormattingUtilities from './TableFormattingUtilities';

export default class DateUtilities {
  public static showTime(timeString: string) {
    if (timeString && !timeString.startsWith('00')) return true;
    return false;
  }

  public static dateIsToday = (date: Date | Moment): boolean => {
    if (date)
      return (
        TableFormattingUtilities.dateFormatString(date) ===
        TableFormattingUtilities.dateFormatString(new Date())
      );
    return false;
  };

  public static toUTC = (date: Date | null | undefined): Date | null => {
    return date ? addMinutes(date, -date.getTimezoneOffset()) : null;
  };

  public static parseISOWithoutTimeZone = (date: string): Date | null => {
    return DateUtilities.toUTC(parseISO(date));
  };

  public static toLocal = (date: Date | null): Date | null => {
    return date ? addMinutes(date, date.getTimezoneOffset()) : null;
  };

  public static todayWithOutTimeZone = (): Date => {
    const date = moment().format('YYYY-MM-DD') + ' 00:00:01'.toString();
    return DateUtilities.parseISOWithoutTimeZone(date) ?? new Date();
  };

  public static dateIsInPast(date: Date | Moment) {
    return new Date(date.toString()) < new Date();
  }

  public static isoWeekDay = (date?: Date | Moment): number | undefined => {
    if (date) return moment(date).isoWeekday();
    return undefined;
  };

  /**
   * Filter, which goes through all properties (on level 1) and converts some typescript specific
   * classes to value type/string type fields so they can be sent through API
   * @param data
   * @returns {any} - object with same props as entry parameter but with normalized data
   */
  public static tryConvertAllMomentsToDates(data: any): any {
    try {
      if (!data) {
        return data;
      }

      const prepared = {};

      if (data instanceof Object) {
        var objectKeys = Object.keys(data);
        for (let key in data) {
          if (key in data && objectKeys.indexOf(key) >= 0) {
            if (!data[key]) continue;
            // Convert moment to Date
            if (moment.isMoment(data[key])) {
              prepared[key] = data[key].toDate();
              continue;
            }
            if (Array.isArray(data[key]) && data[key]) {
              prepared[key] = data[key].join(',');
              continue;
            }
            // Other cases
            prepared[key] = data[key];
          }
        }
      }

      return prepared;
    } catch (exception) {
      console.error('Failed to normalize data. Still proceeding...', exception);
      return data;
    }
  }
}
