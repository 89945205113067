import * as React from 'react';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { AuthUtils } from 'helpers/AuthUtils';
import clsx from 'clsx';

import MobileAppRoutesComponent from './routes/Mobile';

class MainAppComponent extends React.Component<{
  isLoggedIn?: boolean;
}> {
  render() {
    return (
      <div className={clsx(AuthUtils.isClientPortal() && 'client-portal-wrapper')}>
        <MobileAppRoutesComponent />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates={true}
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
        />
      </div>
    );
  }
}

export default MainAppComponent;
