import { APP_NAME, IS_DEV, IS_MOBILE_APP_URL, MOBILE_APP_NAME } from './constant';

const BACKEND_URL = (): string => {
  if (IS_MOBILE_APP_URL)
    return window.location.origin.replace(`://${MOBILE_APP_NAME}.`, '://api.') + '/';
  return window.location.origin.replace(`://${APP_NAME}.`, '://api.') + '/';
};
// By convention we use the backend on the same domain as the UI.
// E.g. 'tms.cargokeep.com' uses backend 'api.cargokeep.com'
// This preserves localhost as is.
export const BACKEND_HOST = IS_DEV ? 'http://localhost:64665/' : BACKEND_URL();

export const BACKEND_HOST_AUTH = (): string => {
  return BACKEND_HOST;
};

export const BACKEND_HOST_MAIN = (): string => {
  return BACKEND_HOST;
};

export const BACKEND_HOST_TRANSLATIONS = (): string => {
  // for developer's convenience, use translations from sandbox normally.
  if (IS_DEV) return 'http://localhost:64665/';
  else return BACKEND_HOST;
};

export const BACKEND_HOST_FILE_UPLOAD = (): string => {
  return BACKEND_HOST;
};

/*
    Backend routes
 */
export const endpoints = {
  auth: {
    register: 'api/account/register',
    login: 'connect/token',
  },
};

/*
    Frontend routes
 */
export const routePaths = {
  dashboard: '/dashboard',
  dashboard1: '/',
  switchVehicle: '/switch-vehicle',
  login: '/login',
  loginClientPortal: '/client-portal',
  changePassword: '/change-password',
  resetPassword: '/reset-password',
  profile: '/profile',
  addressBook: {
    list: '/address-book',
    new: '/address-book/new',
    edit: '/address-book/:id',
    editId: (id: string) => `/address-book/${id}`,
  },
  errors: {
    offline: '/offline',
  },
};

export const navigateToPaths = {
  login: '/login',
};
