import { BACKEND_HOST_MAIN } from 'store/api.constants';
import ApiUtilities from 'helpers/ApiUtils';
import { SimpleRequest } from 'viewModels/common/SimpleRequest';
import { SelectComponentValue } from 'viewModels/common/SelectComponentValue';
import { PlatformSearchIndexResult } from 'shared/types/PlatformSearchIndexResult';
import { FleetSelectComponentRequestViewModel } from 'viewModels/common/FleetSelectComponentRequestViewModel';
import { PackageTypesResponseViewModel } from '../viewModels/packageType';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/selection-controls`;
}

export class SelectComponentApi {
  public static platformSearchIndex(params: SimpleRequest): Promise<PlatformSearchIndexResult[]> {
    return ApiUtilities.handleGet<PlatformSearchIndexResult[]>(`${getRoot()}/platform-search`, {
      ...params,
    });
  }

  public static getProjects(params: SimpleRequest): Promise<SelectComponentValue[]> {
    return ApiUtilities.handleGet<SelectComponentValue[]>(
      `${getRoot()}/project-select-component-values`,
      {
        ...params,
      },
    );
  }
  public static getCustomers(params: SimpleRequest): Promise<SelectComponentValue[]> {
    return ApiUtilities.handleGet<SelectComponentValue[]>(
      `${getRoot()}/customer-select-component-values`,
      {
        ...params,
      },
    );
  }
  public static getPartners(params: SimpleRequest): Promise<SelectComponentValue[]> {
    return ApiUtilities.handleGet<SelectComponentValue[]>(
      `${getRoot()}/partner-select-component-values`,
      {
        ...params,
      },
    );
  }
  public static getUnits(params: SimpleRequest): Promise<SelectComponentValue[]> {
    return ApiUtilities.handleGet<SelectComponentValue[]>(
      `${getRoot()}/unit-select-component-values`,
      {
        ...params,
      },
    );
  }
  public static getVats(params: SimpleRequest): Promise<SelectComponentValue[]> {
    return ApiUtilities.handleGet<SelectComponentValue[]>(
      `${getRoot()}/vat-select-component-values`,
      {
        ...params,
      },
    );
  }

  public static getUnitsFullData(params: SimpleRequest): Promise<PackageTypesResponseViewModel[]> {
    return ApiUtilities.handleGet<PackageTypesResponseViewModel[]>(`${getRoot()}/units`, {
      ...params,
    });
  }

  public static getInvoices(params: SimpleRequest): Promise<SelectComponentValue[]> {
    return ApiUtilities.handleGet<SelectComponentValue[]>(
      `${getRoot()}/invoice-select-component-values`,
      {
        ...params,
      },
    );
  }

  public static getEmployees(params: SimpleRequest): Promise<SelectComponentValue[]> {
    return ApiUtilities.handleGet<SelectComponentValue[]>(
      `${getRoot()}/employee-select-component-values`,
      {
        ...params,
      },
    );
  }

  public static getFleets(
    params: FleetSelectComponentRequestViewModel,
  ): Promise<SelectComponentValue[]> {
    return ApiUtilities.handleGet<SelectComponentValue[]>(
      `${getRoot()}/fleet-select-component-values`,
      {
        ...params,
      },
    );
  }
}
