import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import Fullscreen from 'react-full-screen';
import windowSize from 'react-window-size';
import Navigation from './Navigation';
import NavBar from './NavBar';
import Configuration from './Configuration';
import Loader from '../Loader';
import Aux from '../../../hoc/_Aux';
import * as actionTypes from '../../../store/actions';
import { AuthUtils } from '../../../helpers/AuthUtils';

class AdminLayout extends Component {
  fullScreenExitHandler = () => {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      this.props.onFullScreenExit();
    }
  };

  UNSAFE_componentWillMount() {
    if (
      this.props.windowWidth > 992 &&
      this.props.windowWidth <= 1024 &&
      this.props.layout !== 'horizontal'
    ) {
      this.props.onUNSAFE_componentWillMount();
    }
  }

  mobileOutClickHandler() {
    if (this.props.windowWidth < 992 && this.props.collapseMenu) {
      this.props.onUNSAFE_componentWillMount();
    }
  }

  render() {
    /* full screen exit call */
    document.addEventListener('fullscreenchange', this.fullScreenExitHandler);
    document.addEventListener('webkitfullscreenchange', this.fullScreenExitHandler);
    document.addEventListener('mozfullscreenchange', this.fullScreenExitHandler);
    document.addEventListener('MSFullscreenChange', this.fullScreenExitHandler);

    let mainClass = ['pcoded-wrapper', AuthUtils.isClientPortal() && 'client-portal'];
    if (this.props.layout === 'horizontal' && this.props.subLayout === 'horizontal-2') {
      mainClass = [...mainClass, 'container'];
    }
    return (
      <Aux>
        <Fullscreen enabled={this.props.isFullScreen}>
          <Navigation collapseMenu={this.props.collapseMenu} />
          <NavBar headerBackColor={'background-dark'} />
          <div className="pcoded-main-container" onClick={() => this.mobileOutClickHandler}>
            <div className={mainClass.join(' ')}>
              <div className="pcoded-content">
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <Suspense fallback={<Loader />}>{this.props.children}</Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Configuration />
        </Fullscreen>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultPath: state.defaultPath,
    isFullScreen: state.isFullScreen,
    collapseMenu: state.collapseMenu,
    layout: state.layout,
    subLayout: state.subLayout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFullScreenExit: () => dispatch({ type: actionTypes.FULL_SCREEN_EXIT }),
    onUNSAFE_componentWillMount: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(AdminLayout));
