import * as React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import AdminLayout2 from '../../layout/AdminLayout';
import { store } from 'index';
import { routePaths } from 'store/api.constants';
import asyncComponent from 'util/asyncComponent';
import { injectAsyncReducer } from 'store';

/** REDUCERS **/

class MobileAppRoutesComponent extends React.Component<any> {
  render() {
    return (
      <AdminLayout2>
        <Switch>
          {[routePaths.dashboard, routePaths.dashboard1].map((path, index) => (
            <Route
              exact={true}
              path={path}
              key={index}
              component={asyncComponent(async () => {
                const container = await import('./Dashboard/Dashboard.container');
                const reducer = await import('./Dashboard/Dashboard.reducer');
                injectAsyncReducer(store, reducer.REDUCER_NAME__DASHBOARD, reducer.default);
                return container;
              })}
            />
          ))}
          <Route
            exact={true}
            path={routePaths.switchVehicle}
            key={'switchVehicle'}
            component={asyncComponent(async () => {
              const container = await import('./SwitchVehicle/SwitchVehicle.container');
              const reducer = await import('./SwitchVehicle/SwitchVehicle.reducer');
              injectAsyncReducer(store, reducer.REDUCER_NAME__SWITCH_VEHICLE, reducer.default);
              return container;
            })}
          />
        </Switch>
      </AdminLayout2>
    );
  }
}

export default MobileAppRoutesComponent;
